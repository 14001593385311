:root {
  --ck-border-radius: 8px !important;
}

.ck-editor__editable {
  min-height: 184px;
}

.ck-toolbar {
  height: 56px !important;

  &__items {
    height: 100% !important;
  }
}
